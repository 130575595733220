import Avatar from '@components/Avatar'
import Button from '@components/Button'
import styled from 'styled-components'
import { down } from 'styled-breakpoints'

type IClutchPost = {
  title: string
  author: string
  url: string
  avatarUrl: string
}

const StyledClutchPost = styled.div`
  p {
    font-family: 'Neue Montreal';
  }
`

const StyledTitle = styled.div`
  font-size: 2.4rem;
  line-height: 4rem;
  margin-bottom: 3.2rem;
  max-width: 80%;
`

const StyledAuthor = styled.div`
  color: var(--dark-1);
  letter-spacing: 0.02;
`

const StyledFooter = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 5.6rem;

  ${down('sm')} {
    flex-direction: column-reverse;

    img {
      margin-bottom: 2.4rem;
    }
  }
`

const ClutchPost = ({ title, author, url, avatarUrl }: IClutchPost) => (
  <StyledClutchPost>
    <StyledTitle>{title}</StyledTitle>
    <StyledAuthor>{author}</StyledAuthor>
    <StyledFooter>
      <a href={url} target="_blank" rel="noreferrer">
        <Button text="See the full feedback" />
      </a>
      <Avatar src={avatarUrl} alt={title} />
    </StyledFooter>
  </StyledClutchPost>
)

export default ClutchPost
