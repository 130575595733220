import ClutchPost from '@components/Posts/ClutchPost'
import Container from '@styles/Container'
import HR from '@components/HR'
import SectionHeading from '@components/Headings/SectionHeading'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'

const StyledStickyContainer = styled.div`
  --column-gap: 10rem;
  display: grid;
  column-gap: var(--column-gap);
  row-gap: 4rem;
  align-items: flex-start;

  ${up('lg')} {
    grid-template-columns: 1fr 2fr;
  }

  ${up('xl')} {
    --column-gap: 20rem;
    grid-template-columns: 31.52% 52.67%;
  }
`

const StyledTitle = styled.h2`
  position: relative;
  font-size: 6rem;
  line-height: 9.2rem;
  font-weight: 400;
  margin: 0;
  top: 5rem;
  margin-bottom: 2.4rem;

  .clutch-logo {
    transition: opacity 0.1s, visibility 0.1s;
    height: 60px;

    ${up('lg')} {
      position: absolute;
      right: 0;
      top: 23%;
      height: auto;
    }
  }

  ${up('lg')} {
    position: sticky;
    font-size: 8rem;
    line-height: 11.2rem;
    margin-bottom: 0;
  }
`

const StyledPosts = styled.div`
  > *:not(:last-child) {
    --offset: 4rem;
    margin-bottom: var(--offset);
    padding-bottom: var(--offset);
    border-bottom: 0.1rem solid var(--gray-3);

    ${up('lg')} {
      --offset: 11.2rem;
    }
  }
`

const ClutchSection = () => (
  <section>
    <Container>
      <div>
        <HR className="mb-24" />
        <SectionHeading title="What do you like the most about working with us?" />
      </div>
      <StyledStickyContainer>
        <StyledTitle>
          Empressia is 5 /5 average on Clutch{' '}
          <img
            src="images/clutch-rating.svg"
            className="clutch-logo"
            alt="clutch-rating"
          />
        </StyledTitle>
        <StyledPosts>
          <ClutchPost
            author="Piotr Kot, Project Manager —  Datumo"
            avatarUrl="/images/clutch/datumo.png"
            title="...when I had some questions or was missing necessary knowledge, Empressia was always very eager to explain and help."
            url="https://clutch.co/profile/empressia"
          />
          <ClutchPost
            author="Malgorzata Mrug, Owner - Mrug"
            avatarUrl="/images/clutch/mrug.png"
            title="I will certainly engage with this company again for any website needs that I have in the future."
            url="https://clutch.co/profile/empressia"
          />
        </StyledPosts>
      </StyledStickyContainer>
    </Container>
  </section>
)

export default ClutchSection
