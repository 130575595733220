import { Fragment } from 'react'
import { Chunk, ChunkSpace } from './styles'
import { ChunksProps } from './types'

const Chunks: React.FC<ChunksProps> = ({ items }) => (
  <>
    {items.map((row, index) => (
      <Fragment key={row.join()}>
        {row.reduce(
          (prev, curr) =>
            prev.concat(
              <Fragment key={prev.join() + curr}>
                <Chunk className="js-element">
                  <span className="js-chunk">{curr}</span>
                </Chunk>
                <ChunkSpace className="js-element js-space">&nbsp;</ChunkSpace>
              </Fragment>,
            ),
          [] as any[],
        )}

        {index + 1 !== items.length && <br />}
      </Fragment>
    ))}
  </>
)
export default Chunks
