interface Project {
  title: string
  description: string
  imageSrc: string
  imageAlt: string
  link: string
  staticLink?: boolean
}

const projects = {
  tipply: {
    title: 'Tipply',
    description: 'Online donation system, and integrations for streamers',
    imageSrc: '/images/work/cover/tipply.jpg',
    imageAlt: 'Tipply',
    link: '/work/tipply',
    staticLink: true,
  },
  'label-magazine': {
    title: 'Label Magazine',
    description:
      'Website development and ecommerce for a Polish bimonthly design magazine',
    imageSrc: '/images/work/cover/label-magazine.jpg',
    imageAlt: 'Label Magazine',
    link: '/work/label-magazine',
  },
  'university-hospital-in-krakow': {
    title: 'University Hospital in Kraków',
    description: 'Website for largest clinical hospital in Poland',
    imageSrc: '/images/work/cover/suwk.jpg',
    imageAlt: 'University Hospital in Kraków',
    link: '/work/university-hospital-in-krakow',
    staticLink: true,
  },
  'termy-chocholowskie': {
    title: 'Termy Chochołowskie',
    description: 'The largest water park in Poland',
    imageSrc: '/images/work/cover/termy-chocholowskie.jpg',
    imageAlt: 'Termy Chochołowskie',
    link: '/work/termy-chocholowskie',
  },
  willisch: {
    title: 'Willisch',
    description: 'Ice cream delivered to your door',
    imageSrc: '/images/work/cover/willisch.jpg',
    imageAlt: 'Willisch',
    link: '/work/willisch',
  },
  'analytics-intell': {
    title: 'Analytics Intell',
    description: 'Smart analytics and data intelligence services',
    imageSrc: '/images/work/cover/analytics-intell.jpg',
    imageAlt: 'Analytics Intell',
    link: '/work/analytics-intell',
  },
  'wild-encounter-safaris': {
    title: 'Wild Encounter Safaris',
    description: 'African wildlife photography tours',
    imageSrc: '/images/work/cover/wild-encounter-safaris.jpg',
    imageAlt: 'Wild Encounter Safaris',
    link: '/work/wild-encounter-safaris',
  },
  formobile: {
    title: 'Formobile',
    description: 'platform for European law enforcement agencies.',
    imageSrc: '/images/work/cover/for-mobile.jpg',
    imageAlt: 'Formobile',
    link: '/work/formobile',
  },
  datumo: {
    title: 'DATUMO',
    description: 'provider of the widely understood Big Data.',
    imageSrc: '/images/work/cover/datumo.jpg',
    imageAlt: 'Datumo',
    link: '/work/datumo',
  },
  'adam-mickiewicz-university': {
    title: 'Adam Mickiewicz University',
    description:
      'the major academic institution in Greater Poland and one of the top Polish universities.',
    imageSrc: '/images/work/cover/uam.jpg',
    imageAlt: 'Adam Mickiewicz University',
    link: '/work/adam-mickiewicz-university',
  },
}

export const all = Object.values(projects) as Project[]

// First five projects
export const featured = Object.values(projects).slice(0, 5) as Project[]
