import Link from 'next/link'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import { ImageSet } from 'caravaggio-react'
import Text from '@components/Text'

interface ProjectProps {
  title: string
  description: string
  imageSrc: string
  imageAlt: string
  link: string
  staticLink?: boolean
}

const Figure = styled.figure`
  margin: 0;
  text-align: center;
  overflow: hidden;
`

const ImageWrapper = styled.div`
  border-radius: 0.8rem;
  overflow: hidden;
  transform-origin: center;
  transition: transform 0.3s cubic-bezier(0.17, 0.84, 0.44, 1);

  picture {
    display: block;
    position: relative;
    padding-top: calc(1807 / 1365 * 100%);

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
      border-radius: 0.8rem;
      transform-origin: center;
      transition: transform 0.3s cubic-bezier(0.17, 0.84, 0.44, 1);
    }
  }
`

const Caption = styled.figcaption`
  margin: 3.2rem 0 0;

  ${up('md')} {
    padding: 0 4rem;
    margin: 4.8rem 0 0;
  }

  ${Text} {
    font-family: Campton;
    letter-spacing: -0.5px;
    line-height: 3.2rem;
    margin: 0;
    font-weight: 200;
  }
`

const Container = styled.a`
  display: block;
  contain: content;

  &:hover {
    ${ImageWrapper} {
      transform: scale(0.95);
    }

    picture img {
      transform: scale(1.15);
    }
  }

  &:nth-child(even) {
    ${up('sm')} {
      transform: translateY(18rem);
    }
  }
`

const Project: React.FC<ProjectProps> = ({
  title,
  description,
  imageSrc,
  imageAlt,
  link,
  staticLink,
}) => {
  const structure = (
    <Container href={link} className="js-project">
      <Figure>
        <ImageWrapper>
          <ImageSet
            src={imageSrc}
            alt={imageAlt}
            className="js-image"
            loading="lazy"
            sets={[
              {
                type: 'image/webp',
                rules: {
                  '300w': {
                    opt: {
                      o: 'webp',
                      rs: {
                        s: '440x',
                      },
                    },
                  },
                  '900w': {
                    opt: {
                      o: 'webp',
                      rs: {
                        s: '640x',
                      },
                    },
                  },
                },
              },
              {
                rules: {
                  '400w': {
                    opt: {
                      rs: {
                        s: '440x',
                      },
                    },
                  },
                  '900w': {
                    opt: {
                      rs: {
                        s: '640x',
                      },
                    },
                  },
                },
              },
            ]}
          />
        </ImageWrapper>
        <Caption>
          <Text size="large">
            <strong>{title} -</strong> {description}
          </Text>
        </Caption>
      </Figure>
    </Container>
  )

  return staticLink ? (
    structure
  ) : (
    <Link href={link} passHref>
      {structure}
    </Link>
  )
}

export default Project
