import { ImageSet } from 'caravaggio-react'

interface BubbleProps {
  src: string
  alt: string
  className?: string
}

export const Bubble: React.FC<BubbleProps> = ({ src, alt, className }) => (
  <div className={className}>
    <ImageSet
      src={src}
      alt={alt}
      loading="lazy"
      sets={[
        {
          type: 'image/webp',
          rules: {
            '300w': {
              opt: {
                o: 'webp',
                rs: {
                  s: '256x',
                },
              },
            },
          },
        },
        {
          rules: {
            '300w': {
              opt: {
                rs: {
                  s: '256x',
                },
              },
            },
          },
        },
      ]}
    />
  </div>
)
