import {
  Bubbles,
  Container,
  Content,
  ContentContainer,
  FifaBubble,
  Title,
} from './AboutUsCTA.style'
import { useEffect, useRef } from 'react'

import { Bubble } from './Bubble'
import Button from '@components/Button'
import Link from 'next/link'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import gsap from 'gsap'
import randomNumberBetween from '@utils/randomNumberBetween'
import { windowLoadWithTimetout } from '@utils/timeout'

const titleText = (
  <>
    Behind
    <br />
    every great
    <br /> project is&nbsp;a
    <br /> great team.
  </>
)

export const AboutUsCTA: React.FC = () => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const bubbles = containerRef.current?.querySelectorAll('.js-bubble-item')

    gsap.set(bubbles as gsap.TweenTarget, {
      scale: 0,
    })

    windowLoadWithTimetout(() => {
      bubbles?.forEach((item) => {
        ScrollTrigger.create({
          trigger: item as Element,
          start: '-50% bottom',
          onEnter: () => {
            gsap.to(item as gsap.TweenTarget, {
              scale: 1,
              duration: randomNumberBetween(0.6, 0.8),
              ease: 'elastic.out(1, 0.75)',
            })
          },
        })
      })

      if (window.matchMedia('(min-width: 768px)').matches) {
        bubbles?.forEach((item) => {
          gsap.to(item as gsap.TweenTarget, {
            yPercent: randomNumberBetween(40, 100),
            scrollTrigger: {
              trigger: 'top top',
              endTrigger: containerRef.current as HTMLDivElement,
              scrub: 1.1,
            },
          })
        })
      }
    }, 500)
  }, [])

  return (
    <Container ref={containerRef}>
      <Bubble
        className="js-bubble-item bubble-item bubble-item--free"
        src="/images/team/featured/team-01.jpg"
        alt=""
      />

      <ContentContainer>
        <Bubbles>
          <Bubble
            className="js-bubble-item bubble-item bubble-item-1 bubble-item--behind"
            src="/images/team/featured/team-02.jpg"
            alt=""
          />
          <Bubble
            className="js-bubble-item bubble-item bubble-item-2 bubble-item--behind"
            src="/images/team/featured/team-03.jpg"
            alt=""
          />
          <span className="js-bubble-item bubble-item bubble-item-3" />
          <span className="js-bubble-item bubble-item bubble-item-4" />
          <FifaBubble className="js-bubble-item bubble-item bubble-item-5">
            <img src="/icons/fifa-skills.svg" alt="" />
            <Bubble src="/images/team/featured/team-04.jpg" alt="" />
          </FifaBubble>
          <Bubble
            className="js-bubble-item bubble-item bubble-item-6"
            src="/images/team/featured/team-06.jpg"
            alt=""
          />
          <span className="js-bubble-item bubble-item bubble-item-7" />
          <Bubble
            className="js-bubble-item bubble-item bubble-item-8"
            src="/images/team/featured/team-05.jpg"
            alt=""
          />
          <span className="js-bubble-item bubble-item bubble-item-9" />
        </Bubbles>
        <Content>
          <Title>{titleText}</Title>
          <Link href="/about" passHref>
            <a className="d-inline-block">
              <Button text="Who we are" theme="red" />
            </a>
          </Link>
        </Content>
      </ContentContainer>
    </Container>
  )
}
