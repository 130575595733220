import styled, { css } from 'styled-components'
import { up } from 'styled-breakpoints'

const Grid = styled.div<{ $padding: boolean }>`
  display: grid;
  grid-gap: 6.4rem;

  ${up('sm')} {
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 6.4rem 4rem;
    ${(props) =>
      props.$padding &&
      css`
        padding-bottom: 18rem;
      `}
  }

  ${up('md')} {
    grid-gap: 7.4rem 6.4rem;
  }
`

export default Grid
