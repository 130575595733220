import { useEffect, useRef } from 'react'

import Grid from './Grid'
import Project from './Project'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { all } from '@data/projects-list'
import gsap from 'gsap'
import { isInViewport } from '@utils/isInViewport'
import isNumber from 'lodash/isNumber'
import { windowLoadWithTimetout } from '@utils/timeout'

interface ProjectsGridProps {
  max?: number
}

const ProjectsGrid: React.FC<ProjectsGridProps> = ({ max }) => {
  const gridRef = useRef<HTMLDivElement>(null)
  const items = isNumber(max) ? all.slice(0, max) : all

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
    const projects = gridRef.current?.querySelectorAll('.js-project')

    gsap.set(projects as gsap.TweenTarget, {
      scale: 0.5,
      autoAlpha: 0,
    })

    windowLoadWithTimetout(() => {
      projects?.forEach((project) => {
        const image = project.querySelector('.js-image')

        gsap.set(image, {
          scale: 1.2,
        })

        ScrollTrigger.create({
          trigger: project,
          start: `${
            window !== undefined && isInViewport(project)
              ? '0% bottom'
              : '40% bottom'
          }`,
          once: true,
          onEnter: () => {
            gsap
              .timeline()
              .to(project, { autoAlpha: 1, duration: 0.3, ease: 'expo.out' })
              .to(
                project,
                {
                  scale: 1,
                  duration: 0.6,
                  ease: 'expo.out',
                },
                '<',
              )
              .to(
                image,
                {
                  scale: 1,
                  duration: 1,
                  ease: 'expo.out',
                },
                '<',
              )
              .play()
          },
        })
      })
    })
  }, [max])

  return (
    <Grid ref={gridRef} $padding={items.length % 2 === 0}>
      {items.map((item) => (
        <Project
          key={item.title}
          title={item.title}
          description={item.description}
          imageSrc={item.imageSrc}
          imageAlt={item.imageAlt}
          link={item.link}
          staticLink={item.staticLink}
        />
      ))}
    </Grid>
  )
}

export default ProjectsGrid
