import { Slim, SlimInner } from '@components/styled/ContainerSlim'
import { between, down, up } from 'styled-breakpoints'
import styled, { css } from 'styled-components'

import { H1 } from '@components/Heading'

const hiddenBubble = css`
  pointer-events: none;
  opacity: 0;
  visibility: hidden;
`

export const Bubbles = styled.div`
  position: absolute;
  display: grid;
  grid-template-columns: repeat(3, auto);
  justify-content: end;
  grid-gap: 3.2rem;
  right: -12.8rem;
  top: 0;

  ${between('xs', 'md')} {
    .bubble-item-2 {
      position: relative;
      top: 8rem;
      grid-column-start: 2;
    }
  }

  ${between('xs', 'lg')} {
    .bubble-item-3 {
      display: none;
    }

    .bubble-item-4,
    .bubble-item-5 {
      ${hiddenBubble}
    }
  }

  ${down('xs')} {
    .bubble-item-2 {
      grid-column-start: 3;
    }

    .bubble-item-8 {
      ${hiddenBubble}
    }
  }

  @media screen and (max-width: 1199px) {
    .bubble-item-1,
    .bubble-item-6 {
      ${hiddenBubble}
    }
  }
`

export const FifaBubble = styled.div`
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;

  /* Quote */
  > img {
    position: absolute;
    left: -1.5rem;
    bottom: 1rem;
    z-index: 10;
  }

  /* Smaller image behind quote */
  picture img {
    width: 16rem;
    height: 16rem;
  }
`

export const Container = styled.section`
  position: relative;
  overflow: hidden;
  padding: 25.6rem 0;
  margin: -25.6rem 0;

  .bubble-item {
    width: 25.6rem;
    height: 25.6rem;
    img {
      border-radius: 100%;
    }

    &--free {
      position: absolute;
      left: -4rem;
    }

    &--behind {
      z-index: -1;
    }
  }
`

export const ContentContainer = styled(Slim)`
  position: relative;

  ${up('md')} {
    grid-template-columns: repeat(12, 1fr);
  }
`

export const Content = styled(SlimInner)`
  padding: 25.6rem 0 8rem;

  ${up('sm')} {
    padding: 25.6rem 0 20.8rem;
  }

  ${up('lg')} {
    padding-top: 6.4rem;
  }
`

export const Title = styled(H1).attrs({ as: 'h2' })`
  margin: 0 0 6rem;
`
