import styled from 'styled-components'

const StyledAvatar = styled.img<{ size?: string }>`
  border-radius: 100%;
  object-fit: cover;
  height: ${({ size }) => size || '8.8rem'};
  width: ${({ size }) => size || '8.8rem'};
`

type IProps = {
  src: string
  alt: string
  size?: string
}

const Avatar = ({ src, alt, size }: IProps) => (
  <StyledAvatar src={src} alt={alt} size={size} />
)

Avatar.defaultProps = {
  size: '8.8rem',
}

export default Avatar
