import styled, { keyframes } from 'styled-components'

type IProps = {
  reverse?: boolean
  componentWidth?: string
  duration?: number
  pauseOnHover?: boolean
}

const animation = ({ reverse }: IProps) => keyframes`
  0% {
    transform:
      ${reverse ? 'translateX(-50%)' : 'translateX(0)'};
  }

  100% {
    transform:
      ${reverse ? 'translateX(0)' : 'translateX(-50%)'};
  }
`

const StyledMarqueeContainer = styled.div<IProps>`
  overflow: hidden;

  &:hover {
    > div {
      animation-play-state: ${({ pauseOnHover }) => pauseOnHover && 'paused'};
    }
  }

  > div {
    animation: ${animation} ${({ duration }) => duration || duration}s linear
      infinite forwards;
    white-space: nowrap;
    will-change: transform;
    display: inline-flex;

    > * {
      display: inline-flex;
      width: ${({ componentWidth }) => componentWidth && componentWidth};
      margin-right: 1.6rem;
    }
  }
`

const Marquee: React.FC<IProps> = ({
  reverse,
  componentWidth,
  duration,
  pauseOnHover,
  children,
}) => (
  <StyledMarqueeContainer
    reverse={reverse}
    componentWidth={componentWidth}
    duration={duration}
    pauseOnHover={pauseOnHover}
    className="marquee"
  >
    <div>
      {children}
      {children}
    </div>
  </StyledMarqueeContainer>
)

Marquee.defaultProps = {
  reverse: false,
  componentWidth: 'auto',
  duration: 80,
  pauseOnHover: false,
} as Partial<IProps>

export default Marquee
