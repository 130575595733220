import styled, { css } from 'styled-components'
import { up, down } from 'styled-breakpoints'
import { H2, H4 } from '@components/Heading'

const textLayout = css`
  position: relative;
  display: inline-flex;
  flex-wrap: wrap;
`

export const Chunk = styled.span`
  position: relative;
  display: inline-block;
  overflow: hidden;

  span {
    position: relative;
    display: inline-block;
    transform: translateY(100%);
  }
`

export const ChunkSpace = styled.span`
  &.is-hidden {
    width: 0;
    height: 0;
    overflow: hidden;
    pointer-events: none;
    user-select: none;
  }
`

export const Container = styled.div`
  margin: 0 0 8rem;

  ${up('lg')} {
    margin: 0 0 16.8rem;
  }
`

export const Title = styled(H2)`
  ${textLayout}
  margin: 0;

  ${up('sm')} {
    align-items: baseline;
  }

  ${up('lg')} {
    line-height: 1.2;
  }

  ${down('lg')} {
    br {
      display: none;
    }
  }
`

export const Subtitle = styled(H4)`
  ${textLayout}
  margin: 0.8rem 0 0;
  line-height: 1.6;
`

export const FloatingText = styled(Chunk)`
  font-family: 'Neue Montreal';
  font-size: 1.4rem;
  line-height: 2.8rem;
  font-weight: 400;
  letter-spacing: normal;

  span {
    padding-top: 22%;
  }
`
