import styled from 'styled-components'

const StyledSectionHeading = styled.h2<{ fontSize?: string }>`
  margin-bottom: 6.4rem;
  font-size: ${({ fontSize }) => fontSize || '1.6rem'};
  font-weight: 400;
  font-family: 'Neue Montreal';
  letter-spacing: 0.02rem;
  margin-top: 0;
`

type IProps = {
  title: string
}

const SectionHeading = ({ title }: IProps) => (
  <StyledSectionHeading>{title}</StyledSectionHeading>
)

export default SectionHeading
