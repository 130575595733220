import { NextPage } from 'next'
import Head from 'next/head'
import Link from 'next/link'
import { AboutUsCTA } from '@components/AboutUsCTA'
import BorderText from '@components/BorderText'
import Button from '@components/Button'
import ClutchSection from '@components/ClutchSection/ClutchSection'
import PageHeader from '@components/PageHeader'
import Text from '@components/Text'
import { Container, ContainerSlim } from '@components/styled'
import ProjectsGrid from '@components/ProjectsGrid'
import { H4 } from '@components/Heading'
import HeadingsMarquee from '@components/HeadingsMarquee'
import { Slider as ProcessSlider } from '@components/Process'
import Section from '@components/Section'
import HR from '@components/HR'
import SectionHeading from '@components/Headings/SectionHeading'

const IndexPage: NextPage = () => (
  <>
    <Head>
      <title>Empressia - Web and Mobile Development Company</title>
      <link rel="icon" href="/favicon.ico" />
    </Head>

    <PageHeader
      title={[
        ['Hi 👋', 'Your experts', 'in'],
        ['good ideas', 'here'],
      ]}
    >
      Hi 👋 Your experts in
      <br />
      good ideas here
    </PageHeader>

    <AboutUsCTA />

    <ContainerSlim className="mb-80">
      <div style={{ maxWidth: 500 }}>
        <H4 as="h2">How can we help you?</H4>
        <Text className="my-0" size="big" color="gray">
          Our software house agency is the place where we will help you realize
          your vision and create real projects in virtual world.
        </Text>
      </div>
    </ContainerSlim>

    {/* TODO: The same headings like on 'what we do' page (merge) */}
    <HeadingsMarquee
      headings={[
        {
          text: 'Web development',
          href: '/what-we-do/#web-development',
        },
        {
          text: 'E-commerce',
          href: '/what-we-do/#e-commerce',
        },
        {
          text: 'Mobile development',
          href: '/what-we-do/#mobile-development',
        },
        {
          text: 'Product design',
          href: '/what-we-do/#product-design',
        },
      ]}
    />

    <div className="mb-80 mb-md-200" />

    <Container className="text-center mb-80 mb-md-200">
      <BorderText link="/estimate-project" buttonText="Estimate your project">
        Are you ready to make
        <br />
        something great?
      </BorderText>
    </Container>

    <ContainerSlim className="mb-80 mb-md-128 text-center">
      <H4 as="h2">Where, what, how?</H4>
      <Text className="my-0" size="big" color="gray">
        We want you to know that we work with partners not only in Poland but
        all over the world. We provide services both for foreign interactive
        agencies (as subcontractors) and for businesses that care about their
        online image. We operate all over Europe and in countries such as the
        United States, Canada, and Australia.
      </Text>
    </ContainerSlim>

    <Container>
      <ProjectsGrid max={6} />

      <Link href="/work" passHref>
        <a className="d-block text-center mt-80 mt-mb-128 mb-80 mb-md-160">
          <Button
            theme="red"
            text="See all projects"
            style={{ margin: '0 auto' }}
          />
        </a>
      </Link>
    </Container>

    <Section>
      <Container>
        <div>
          <HR className="mb-24" />
          <SectionHeading title="Our Process" />
        </div>
      </Container>

      <ProcessSlider />
    </Section>

    <div className="mb-80 mb-md-200" />

    <div className="mb-80 mb-md-200">
      <ClutchSection />
    </div>
  </>
)

export default IndexPage
