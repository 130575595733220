import { useEffect, useRef } from 'react'
import gsap from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import isNil from 'lodash/isNil'
import debounce from 'lodash/debounce'
import { ContainerSlim } from '@components/styled'
import { windowLoadWithTimetout } from '@utils/timeout'
import Chunks from './Chunks'
import { Container, Title, FloatingText, Subtitle } from './styles'
import { PageHeaderProps } from './types'

const PageHeader: React.FC<PageHeaderProps> = ({
  title,
  subtitle,
  floatingText,
  ...props
}) => {
  const containerRef = useRef<HTMLDivElement>(null)

  useEffect(() => {
    gsap.registerPlugin(ScrollTrigger)
  }, [])

  useEffect(() => {
    const container = containerRef.current as Element
    const spaces = container.querySelectorAll<HTMLElement>('.js-element')
    let observer: ResizeObserver

    windowLoadWithTimetout(() => {
      ScrollTrigger.create({
        trigger: container,
        start: '-50% bottom',
        once: true,
        onEnter: () => {
          const chunks = container.querySelectorAll('.js-chunk') as NodeListOf<
            Element
          >

          gsap.to(chunks, {
            y: 0,
            stagger: 0.2,
            duration: 1.2,
            ease: 'expo.out',
          })
        },
      })

      observer = new ResizeObserver(
        debounce(() => {
          spaces.forEach((space) => space.classList.remove('is-hidden'))

          setTimeout(() => {
            spaces.forEach((space, i) => {
              if (
                i > 0 &&
                space.offsetTop > spaces[i - 1].offsetTop &&
                space.classList.contains('js-space')
              ) {
                // eslint-disable-next-line no-param-reassign
                space.classList.add('is-hidden')
              }
            })
          })
        }, 300),
      )

      observer.observe(container)
    })

    return () => {
      if (observer instanceof ResizeObserver) {
        observer.disconnect()
      }
    }
  }, [title])

  return (
    <ContainerSlim>
      <Container {...props} ref={containerRef}>
        <Title as="h1">
          <Chunks items={title} />
          {floatingText && (
            <FloatingText>
              <span className="js-chunk">{floatingText}</span>
            </FloatingText>
          )}
        </Title>

        {!isNil(subtitle) && (
          <Subtitle className="my-0" as="h2">
            <Chunks items={subtitle} />
          </Subtitle>
        )}
      </Container>
    </ContainerSlim>
  )
}

export default PageHeader
