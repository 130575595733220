import styled from 'styled-components'
import { down } from 'styled-breakpoints'

const Section = styled.section`
  background: #f6f6f6;
  padding: 12.8rem 0 16rem;

  ${down('md')} {
    padding: 4.8rem 0;
  }
`

export default Section
