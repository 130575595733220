import Container from '@components/styled/Container'
import HR from '@components/HR'
import Link from 'next/link'
import Marquee from '@components/Marquee'
import styled from 'styled-components'
import { H2 } from '@components/Heading'
import { down } from 'styled-breakpoints'

type HeadingsMarqueeProps = {
  headings: Array<{ text: string; href: string }>
}

const StyledMarqueeContainer = styled.div`
  .marquee {
    padding: 11.2rem 0;

    ${down('md')} {
      padding: 6rem 0;
    }

    ${down('sm')} {
      padding: 4.8rem 0;
    }

    > div:hover {
      h2:not(:hover) {
        color: var(--gray-3);
      }
    }
  }
`

const StyledHeading = styled(H2)`
  display: block;
  margin: 0 6.4rem;
`

// TODO: Add stoping that marquee on hover and add smooth scroll to section on click
const HeadingsMarquee: React.FC<HeadingsMarqueeProps> = ({ headings }) => {
  return (
    <StyledMarqueeContainer>
      <Container>
        <HR />
      </Container>
      <Marquee duration={30}>
        {headings.map((heading) => (
          <Link href={heading.href} key={heading.text}>
            <a>
              <StyledHeading>{heading.text}</StyledHeading>
            </a>
          </Link>
        ))}
      </Marquee>
      <Container>
        <HR />
      </Container>
    </StyledMarqueeContainer>
  )
}

export default HeadingsMarquee
