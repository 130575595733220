import Link from 'next/link'
import styled from 'styled-components'
import { up } from 'styled-breakpoints'
import Button from '@components/Button'
import { H2 } from '@components/Heading'

interface BorderTextProps {
  link: string
  buttonText: string
}

const Container = styled.div`
  position: relative;
`

const Text = styled(H2)`
  margin: 0;
  -webkit-text-fill-color: transparent;
  -webkit-text-stroke-width: 1px;
  -webkit-text-stroke-color: var(--gray-4);
`

const ButtonContainer = styled.div`
  ${up('md')} {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }
`

const BorderText: React.FC<BorderTextProps> = ({
  children,
  link,
  buttonText,
}) => (
  <Container>
    <Text className="mb-32 mb-md-0" as="div">
      {children}
    </Text>
    <ButtonContainer>
      <Link href={link}>
        <a className="d-inline-block">
          <Button text={buttonText} theme="red" />
        </a>
      </Link>
    </ButtonContainer>
  </Container>
)

export default BorderText
